import React from 'react'
import { green } from '@material-ui/core/colors';
import * as Liff from '../../apis/liff';
import Box from '@material-ui/core/Box';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import Typography from '@material-ui/core/Typography';
import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';

export default ({ location }) => {
  const uri = location.search.split("?uri=")[1];

  React.useEffect(() => {
    if (uri) {
      window.location.href = uri;
    }
  }, [uri])

  const onClose = () => {
    if (Liff.isInClient()) {
      Liff.closeWindow();
    } else {
      window.location.href = 'me.moronline://exit';
    }
  }

  if (!uri) {
    return false;
  }

  return (
    <Layout bg="bg4" title="">
      <Box p={2}>
        <Typography variant="h5" component="h1" color="textPrimary">
          กรุณาปิดหน้านี้หากท่านชำระเงินเรียบร้อยแล้ว
        </Typography>
        <Box mt={3} textAlign="center">
          <PaymentOutlinedIcon style={{ color: green[500], fontSize: 50 }} />
        </Box>
        <Box mt={3}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={onClose}
          >
            ปิดหน้านี้
          </Button>
        </Box>
      </Box>
    </Layout >
  );
}
